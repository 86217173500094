import React from 'react'
import EnterpriseKelpLanding, {
  type EnterpriseKelpLandingProps,
} from 'features/enterprise/kelp/components/EnterpriseKelpLanding'
import * as enterprise from 'libs/enterprise'

type Props = EnterpriseKelpLandingProps

export default function EnterpriseKelpSouthernCalifornia(props: Props) {
  return (
    <EnterpriseKelpLanding
      {...props}
      partner={enterprise.KELP_SOCAL_KEY}
      className="EnterpriseKelpSouthernCalifornia"
    />
  )
}
